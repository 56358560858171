import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI & Components
import FooterNote from 'ui-kit/footer-note/footer-note';
import Spinner from 'ui-kit/spinner/spinner';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import ChangeEmailForm from 'components/change-email-form/change-email-form.component';
import ChangePassword from 'components/change-password-request/change-password-request.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import PageBlocks from 'components/page-blocks/page-blocks';
import SignInForm, {
    SignInErrorModalContent,
    SignInLockedFailureModalContent,
    SignInUnknownFailureModalContent
} from 'components/sign-in-form/sign-in-form.component';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// State
import { accountLoginRoutine } from 'state/account/account.routines';
import { accountStateSelector } from 'state/account/account.selectors';
import { APILoginPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { resetCart } from 'state/cart/cart.reducers';
import { membershipRegistrationConfirmPaymentRoutine } from 'state/membership-registration/membership-registration.routines';

// Utils and types
import { Login, LoginRequestErrorPayload } from 'types/login';

import dynamicGreeting from 'util/dynamicGreeting';
import { getPhoneNumber } from 'util/globalVariables';
import storageHelper from 'util/storageHelper';

// Styles
import './sign-in.styles.scss';
import { BIRDI_PLANS } from 'enums/plans';

interface ModalContent {
    title: string;
    subtitle?: string | JSX.Element;
    body: JSX.Element;
    submit: string;
    dataGALocation: string;
    subtitleClass?: string;
    type?: 'default' | 'danger' | 'primary';
    withIcon?: boolean;
    isModalCentered?: boolean;
    showClose?: boolean;
    className?: string;
}

const setSessionStorage = (login: Login): void => {
    if (login.rememberMe === true) {
        storageHelper.session.setRememberMe(login.email, String(login.rememberMe));
    } else {
        storageHelper.session.removeRememberMe();
    }
};

const SignIn = ({ location, data }: { location: any; data: GatsbyTypes.SignInPageDataQuery }): ReactElement => {
    const { t } = useTranslation();
    const { isLoggingIn } = useSelector(accountStateSelector);

    const dispatch = useDispatch();
    const [screen, setScreen] = useState<'updateEmail' | 'updatePassword'>();
    const [credentials, setCredentials] = useState<Login>();
    const [isLoadingEmailChange, setLoadingEmailChange] = useState<boolean>(false);
    const [isLoadingEmailVerification, setLoadingEmailVerification] = useState<boolean>(false);

    const onSubmitSignInForm: (login: Login) => void = (login) => {
        setCredentials(login);
        dispatch(
            accountLoginRoutine.trigger({
                login,
                onSuccess: (response: APILoginPayload) => {
                    if (response?.emailUpdate === 'true') {
                        // Show email update success modal
                        showSuccessEmailChangeModal();
                    }
                    // Moved the redirect logic into account.sagas.ts, since the get-started page
                    // also triggers the accountLoginRoutine.
                    setSessionStorage(login);
                    setLoadingEmailChange(false);
                    /**
                     * reset cart states
                     * Context: in some situations it may happen that getCart finishes executing even after logout,
                     * this would save a cart that may not belong to the new user logging in.
                     * Needs to review setLoggedOutInterceptor() function
                     */
                    dispatch(resetCart());
                },
                onFailure: (err: LoginRequestErrorPayload) => {
                    setLoadingEmailChange(false);
                    if (err && typeof err.error === 'string') {
                        switch (err.error) {
                            case 'user_locked':
                                showUserLockedModal();
                                break;
                            case 'email_already_in_use':
                                // Trigger screen to update user's email
                                setScreen('updateEmail');
                                break;
                            case 'reset_password_required':
                                // Show we've sent an email screen to change user's password
                                setScreen('updatePassword');
                                break;
                            case 'email_changed_reset_password_required':
                                // Show we've sent an email screen
                                setScreen('updatePassword');
                                // Show success modal for email change
                                showSuccessEmailChangeModal();
                                break;
                            case 'email_update_attempt_already_in_use':
                                // Show email already in use screen
                                setScreen('updateEmail');
                                // Show error modal of email already in use
                                showDuplicatedEmailErrorModal();
                                break;
                            case 'migration_failed':
                                if (err.description?.includes('Username can only contain alphanumeric characters')) {
                                    showMigrationErrorModal();
                                } else {
                                    showUnknownErrorModal(err);
                                }
                                break;
                            case 'invalid_grant':
                                // Show modal to inform to the user that need to validate his email
                                if (
                                    err.description?.includes('The user name not confirmed Email.') ||
                                    err.description?.includes(
                                        'A confirmation email has been sent. Please check your inbox.'
                                    )
                                ) {
                                    navigate('/confirm-email?flow=unverified-email');
                                } else {
                                    showUnknownErrorModal(err);
                                }
                                break;
                            default:
                                if (screen === 'updateEmail') {
                                    // Show update email unknown error modal
                                    showEmailChangeUnknownErrorModal();
                                } else {
                                    // Show generic unknown error modal
                                    showUnknownErrorModal(err);
                                }
                        }
                    }
                }
            })
        );
    };

    const onEmailUpdateSubmit = (newEmail?: string) => {
        if (credentials) {
            const login: Login = { ...credentials, ...{ newEmail } };
            onSubmitSignInForm(login);
            setLoadingEmailChange(true);
        }
    };

    const eyebrowText = React.useCallback(() => {
        const eyebrowText =
            screen === 'updatePassword' || screen === 'updateEmail' ? 'accountEyebrowText' : 'eyebrowText';
        return t(`signIn.labels.${eyebrowText}`);
    }, [screen, t]);

    const title = React.useCallback((): string => {
        if (!screen) {
            return dynamicGreeting(
                t('signIn.labels.title.morning'),
                t('signIn.labels.title.afternoon'),
                t('signIn.labels.title.evening')
            );
        } else {
            return t(`signIn.${screen}.title`);
        }
    }, [screen, t]);

    //
    // -- Modal definition
    //
    const openSuccessModal = React.useCallback(
        ({
            title,
            subtitle,
            body,
            submit,
            dataGALocation,
            subtitleClass = '',
            showClose = true,
            className = ''
        }: ModalContent) => {
            dispatch(
                openModal({
                    isModalCentered: true,
                    bodyContent: (
                        <BirdiModalContent
                            body={body}
                            subTitle={subtitle}
                            icon="default"
                            title={title}
                            subtitleClass={subtitleClass}
                        />
                    ),
                    ctas: [
                        {
                            dataGALocation: dataGALocation,
                            label: submit,
                            onClick: () => {
                                dispatch(closeModal({}));
                                return false;
                            },
                            variant: 'primary'
                        }
                    ],
                    showClose: showClose,
                    className: className
                })
            );
        },
        [dispatch]
    );

    const openDefaultErrorModal = React.useCallback(
        ({ title, body, submit, dataGALocation, type, withIcon = true, isModalCentered = false }: ModalContent) => {
            dispatch(
                openModal({
                    showClose: false,
                    size: 'lg',
                    type: type ? type : 'danger',
                    isModalCentered: isModalCentered,
                    headerContent: <BirdiModalHeaderDanger icon={withIcon ? 'alert' : undefined} headerText={title} />,
                    bodyContent: body,
                    ctas: [
                        {
                            label: submit,
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: dataGALocation
                        }
                    ]
                })
            );
        },
        [dispatch]
    );

    //
    // -- Success modals
    //

    const showSuccessEmailChangeModal = React.useCallback(() => {
        openSuccessModal({
            title: t('modals.emailUpdateSuccess.title'),
            subtitle: <h4>{t('modals.emailUpdateSuccess.subtitle')}</h4>,
            body: t('modals.emailUpdateSuccess.body'),
            submit: t('modals.emailUpdateSuccess.submit'),
            dataGALocation: 'SignInSuccessEmailChange',
            subtitleClass: 'modal-subtitle'
        });
    }, [openSuccessModal, t]);

    const showSuccessfulPasswordChangeModal = React.useCallback(() => {
        openSuccessModal({
            title: t('modals.resetPasswordSuccessSignIn.title'),
            body: t('modals.resetPasswordSuccessSignIn.body'),
            submit: t('modals.resetPasswordSuccessSignIn.submit'),
            dataGALocation: 'PasswordChangeRequest',
            subtitleClass: 'modal-subtitle'
        });
    }, [openSuccessModal, t]);

    const showSuccessEmailVerificationModal = React.useCallback(() => {
        openSuccessModal({
            title: t('modals.emailVerification.title'),
            subtitle: <h4>{t('modals.emailVerification.subtitle')}</h4>,
            body: t('modals.emailVerification.body'),
            submit: t('modals.emailVerification.submit'),
            dataGALocation: 'EmailVerificationModal',
            subtitleClass: 'modal-subtitle'
        });
    }, [openSuccessModal, t]);

    const showSuccesAccountUnblockedModal = React.useCallback(() => {
        openSuccessModal({
            title: t('modals.UnblockAccount.title'),
            body: t('modals.UnblockAccount.body'),
            submit: t('modals.UnblockAccount.submit'),
            dataGALocation: 'UnblockAccountModal',
            showClose: false,
            className: 'account-unblocked-modal'
        });
    }, [openSuccessModal, t]);

    //
    // -- Error modals
    //

    const showDuplicatedEmailErrorModal = React.useCallback(() => {
        openDefaultErrorModal({
            title: t('modals.emailAlreadyInUse.title'),
            body: (
                <SignInErrorModalContent
                    title={t('modals.emailAlreadyInUse.body')}
                    body={
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('modals.emailAlreadyInUse.help', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })
                                )
                            }}
                        ></p>
                    }
                />
            ),
            submit: t('modals.emailAlreadyInUse.submit'),
            dataGALocation: 'SignInUpdateEmailDuplicatedError'
        });
    }, [openDefaultErrorModal, t]);

    const showEmailChangeUnknownErrorModal = React.useCallback(() => {
        openDefaultErrorModal({
            title: t('modals.emailUpdateError.title'),
            body: (
                <SignInErrorModalContent
                    title={t('modals.emailUpdateError.body')}
                    body={
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('modals.emailUpdateError.help', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })
                                )
                            }}
                        ></p>
                    }
                />
            ),
            submit: t('modals.emailUpdateError.submit'),
            dataGALocation: 'SignInUpdateEmailUnknownError'
        });
    }, [openDefaultErrorModal, t]);

    const showMigrationErrorModal = React.useCallback(() => {
        openDefaultErrorModal({
            title: t('modals.signInMigrationFailure.title'),
            body: (
                <SignInErrorModalContent
                    title={t('modals.signInMigrationFailure.body')}
                    body={
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('modals.signInMigrationFailure.help', { phoneNumber: getPhoneNumber({}) }),
                                    {
                                        allowedTags: ['strong', 'br']
                                    }
                                )
                            }}
                        />
                    }
                />
            ),
            submit: t('modals.signInMigrationFailure.submit'),
            dataGALocation: 'SignInMigrationFailedInvalidUsername'
        });
    }, [openDefaultErrorModal, t]);

    const showUnknownErrorModal = React.useCallback(
        (err: LoginRequestErrorPayload) => {
            openDefaultErrorModal({
                title: t('modals.signInUnknownFailure.header'),
                body: (
                    <>
                        <SignInUnknownFailureModalContent translation={t} body={err.description} />
                        <PageBlocks pageLocation={location.pathname} dataGALocation={'Signin'} modalBlock={true} />
                    </>
                ),
                submit: t('modals.signInUnknownFailure.submit'),
                dataGALocation: 'SigninUnknownError'
            });
        },
        [openDefaultErrorModal, t, location]
    );

    const showUserLockedModal = React.useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                bodyContent: (
                    <>
                        <SignInLockedFailureModalContent translation={t} />
                        <PageBlocks pageLocation={location.pathname} dataGALocation={'Signin'} modalBlock={true} />
                    </>
                ),
                ctas: [
                    {
                        label: t('modals.signInLockedFailure.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            navigate('/');
                        },
                        dataGALocation: 'SigninLockedError'
                    }
                ]
            })
        );
    }, [dispatch, location, t]);

    // The successful password change modal should be displayed just once.
    // Implementing a use effect with empty dependencies to avoid calling
    // this function when the dependencies are updated.
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const hashParams = new URLSearchParams(location.hash);

        let verificationEmail = '';
        if (urlParams.get('success') && urlParams.size === 1 && !hashParams.get('flow')) {
            showSuccessfulPasswordChangeModal();
        }
        if (hashParams.get('flow') && hashParams.get('flow') === 'email-verification') {
            location.hash.split('&').forEach((pair: any) => {
                const [key, value] = pair.split('=');
                if (key === 'email') verificationEmail = value;
            });
            setLoadingEmailVerification(true);
            _cio.page(window.location.href);

            dispatch(
                membershipRegistrationConfirmPaymentRoutine.trigger({
                    email: verificationEmail,
                    onSuccess: (response: { alreadyActivated: boolean }) => {
                        if (!response.alreadyActivated) {
                            showSuccessEmailVerificationModal();

                            if (response.planAlias === BIRDI_PLANS.BRD_02) {
                                _cio.identify({
                                    id: verificationEmail,
                                    // Strongly recommended when you first identify someone
                                    created_at: Math.floor(Date.now() / 1000)
                                });
                                _cio.track('email_verified', { verified: 1 });
                            }
                        }

                        setLoadingEmailVerification(false);
                    },
                    onFailure: () => {
                        navigate('/link-expired?flow=email-verification');
                        setLoadingEmailVerification(false);
                    }
                })
            );
        }

        if (
            urlParams.has('success') &&
            urlParams.get('success') === 'true' &&
            urlParams.get('flow') &&
            urlParams.get('flow') === 'unblock'
        ) {
            showSuccesAccountUnblockedModal();
        } else if (urlParams.get('message')?.includes('expired') && urlParams.get('success') === 'false') {
            navigate('/link-expired?flow=unblock-account');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WorkflowLayout
            backgroundImage={data.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: eyebrowText() }}
            anonymousOnly={true}
            className="sign-in-page"
        >
            {!isLoadingEmailVerification ? (
                <>
                    <WorkflowLayoutFormWrapper eyebrowText={eyebrowText()} title={title()}>
                        <br />
                        {screen === 'updateEmail' ? (
                            <>
                                <ChangeEmailForm onSubmit={onEmailUpdateSubmit} isLoading={isLoadingEmailChange} />
                            </>
                        ) : screen === 'updatePassword' && credentials ? (
                            <ChangePassword username={credentials?.email} />
                        ) : (
                            <>
                                <SignInForm onSubmit={onSubmitSignInForm} isLoggingIn={isLoggingIn} />
                                {!ENABLE_MEMBERSHIP && (
                                    <div className="mx-0 mx-lg-5">
                                        <PageBlocks pageLocation={location.pathname} dataGALocation={'Signin'} />
                                    </div>
                                )}
                            </>
                        )}
                    </WorkflowLayoutFormWrapper>
                </>
            ) : (
                <Spinner isVisible={isLoadingEmailVerification} t={t} />
            )}
            {screen === 'updateEmail' && (
                <FooterNote
                    body={t('signIn.updateEmail.additionalHelp', {
                        phoneNumber: getPhoneNumber({ isPlainText: true })
                    })}
                />
            )}
        </WorkflowLayout>
    );
};

export default SignIn;

export const query = graphql`
    query SignInPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
